import React, { useRef, useState, useEffect } from 'react';
import './AdminDashboard.css';

const data = [
    {
        "MonthName": "January",
        "data": [
            { "EmployeeName": "Employee1", "TotalPatients": 10, "Totalstrips": 5, "TotalPapValue": 1000, "TotalVials": 3 },
            { "EmployeeName": "Employee2", "TotalPatients": 8, "Totalstrips": 3, "TotalPapValue": 900, "TotalVials": 2 }
        ]
    },
    {
        "MonthName": "February",
        "data": [
            { "EmployeeName": "Employee1", "TotalPatients": 12, "Totalstrips": 6, "TotalPapValue": 1100, "TotalVials": 4 },
            { "EmployeeName": "Employee3", "TotalPatients": 5, "Totalstrips": 2, "TotalPapValue": 500, "TotalVials": 1 }
        ]
    }    
];



const Test = () => {

    const tableData = {};
    const months = data.map(month => month.MonthName);

    data.forEach(monthData => {
        const monthName = monthData.MonthName;

        monthData.data.forEach(employee => {
            const { EmployeeName, TotalPatients, Totalstrips, TotalPapValue, TotalVials } = employee;

            if (!tableData[EmployeeName]) {
                tableData[EmployeeName] = {};
            }
            
            tableData[EmployeeName][monthName] = { TotalPatients, Totalstrips, TotalPapValue, TotalVials };
        });
    });

    console.log('tableData', tableData);


    return (
        <>
            <h1>Test</h1>   

            <div>{JSON.stringify(months)}</div>
            <div>{JSON.stringify(tableData)}</div>

            <table border="1">
                <thead>
                    <tr>
                        <th>Employee Name</th>                        
                        {months.map(month => (
                            <th key={month}>{month}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(tableData).map(employeeName => (
                        <tr key={employeeName}>
                            <td>{employeeName}</td>
                            {months.map(month => (
                                <td key={month}>
                                    {tableData[employeeName][month] ? (
                                        <>
                                            <div>Total Patients: {tableData[employeeName][month].TotalPatients}</div>
                                            <div>Total Strips: {tableData[employeeName][month].Totalstrips}</div>
                                            <div>Total Pap Value: {tableData[employeeName][month].TotalPapValue}</div>
                                            <div>Total Vials: {tableData[employeeName][month].TotalVials}</div>
                                        </>
                                    ) : (
                                        "No Data"
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>        
        </>
    )
};

export default Test;