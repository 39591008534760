import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';
import axios from "axios";
import configData from '../../../Config/Config.json';
import { formatDate, getZoneClass } from '../../../Service/Common';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { useLocation, useNavigate } from 'react-router-dom';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ProductDashboard = () => {

    const url = `${configData.REPORT_URL}/Reports/BrandMonthSummary`;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);
    const query = useQuery();
    const brandName = query.get('brandName');
    const zoneId = query.get('zoneId');
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {

            const paramObj = {
                // Startdate: formatDate(startDate),
                // Enddate: formatDate(endDate),
                "ZoneID": 1003
            };

            const resp = await axios.post(url, paramObj);
            const filteredData = resp.data.Data.filter(item => item.MedicineName.toLowerCase() === brandName.toLowerCase());
            if (resp.status === 200) {
                setData(filteredData);
            }
        };

        fetchData();
    }, []);




    return (
        <div className='kam-main-content'>

            <div className='flex justify-content-center align-content-center'>
                <div className="back-btn flex align-items-center">
                    <img alt="logo" src={process.env.PUBLIC_URL + '/back-button.png'} onClick={() => navigate('/user-dashboard')} />
                </div>
                <div className="box-shadow patients-dr-wrapper patient-header align-content-center">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <span className={`${getZoneClass(zoneId)}`}>{`${brandName.toUpperCase()}`}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='kam-dashboard-wrapper'>
                {data.length === 0 && <Skeleton height="20rem" animation='wave' shape='rectangle' className='kam-dashboard-table-skelton'></Skeleton>}
                {data.length > 0 &&
                    <div className='table-data flex align-items-start justify-content-center'>
                        <DataTable value={data} tableStyle={{ minWidth: '50rem' }}>
                            <Column field="MedicineName" header="Brand"></Column>
                            <Column field="OrderMonthName" header="Month"></Column>
                            <Column field="OrderYear" header="Year"></Column>
                            <Column field="TotalCount" header="Count"></Column>
                        </DataTable>
                    </div>
                }
            </div>

        </div>
    )
};

export default ProductDashboard;