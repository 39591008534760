export const ChartBackground = '#262d47';

export const Brand = {
    Oncyclo: "Oncyclo",
    Revugam: "Revugam",
    Thymogam: "Thymogam",
    Revugam25: "Revugam-25"
}

export const Zone = {
    North: "North",
    East: "East",
    South: "South",
    West: "West"
}



