import React from 'react';
import './PanIndiaDrPatient.scss';
import configData from '../../Config/Config.json';
import { getPanIndiaData } from '../../Service/Common';
import { Skeleton } from 'primereact/skeleton';
import { useNavigate } from 'react-router-dom';



const PanIndiaDrPatient = (props) => {

    const [drZoneWise, setDrZoneWise] = React.useState([]);
    const [totalDrs, setTotalDrs] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const [patientZoneWise, setPatientZoneWise] = React.useState([]);
    const [totalPatient, setTotalPatient] = React.useState([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            await getPanIndiaData(`${configData.REPORT_URL}/Reports/TotalDoctorsZoneWise`, setDrZoneWise);
            await getPanIndiaData(`${configData.REPORT_URL}/Reports/TotalDoctors`, setTotalDrs);
            await getPanIndiaData(`${configData.REPORT_URL}/Reports/TotalPatientsZoneWise`, setPatientZoneWise);
            await getPanIndiaData(`${configData.REPORT_URL}/Reports/TotalPatients`, setTotalPatient);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const panIndiaHandler = (type) => {
        console.log('type 1:', type);
    };

    return (
        <>

            <div className="grid">

                <div className="col-4" onClick={() => panIndiaHandler('full')}>
                    <div className='pan-india-wrapper'>
                        {isLoading && <Skeleton height="6rem" animation='wave' shape='rectangle' className='pan-india-skelton custom-skeleton'></Skeleton>}
                        {
                            !isLoading &&
                            <div className="box-shadow patients-dr-wrapper" onClick={() => navigate(`/patient-dashboard?patient=${totalPatient}`)}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <b>No. of Patients</b>
                                                <span>{totalPatient}</span>
                                            </td>
                                            <td>
                                                <img src={process.env.PUBLIC_URL + '/img/patient.png'} alt="React Logo" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }

                        {isLoading && <Skeleton height="6rem" animation='wave' shape='rectangle' className='pan-india-skelton'></Skeleton>}
                        {
                            !isLoading &&
                            <div className="box-shadow patients-dr-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <b>No. of Doctors</b>
                                                <span className='red'>{totalDrs}</span>
                                            </td>
                                            <td>
                                                <img src={process.env.PUBLIC_URL + '/img/doctor.png'} alt="React Logo" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>
                </div>

                <div className="col-8" onClick={() => panIndiaHandler('full')}>
                    <div className='zone-india-wrapper'>

                        {isLoading && <Skeleton height="6rem" animation='wave' shape='rectangle' className='zone-india-skelton'></Skeleton>}


                        {
                            !isLoading &&
                            <div className="box-shadow patients-dr-zone-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='east' colSpan={2}>East</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='east'>
                                                <b>Doctors</b>
                                                <span>{drZoneWise.length > 0 && drZoneWise[0].TotalDoctors}</span>
                                            </td>
                                            <td className='east'>
                                                <b>Patients</b>
                                                <span>{patientZoneWise.length > 0 && patientZoneWise[1].TotalPatients}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }

                        {isLoading && <Skeleton height="6rem" animation='wave' shape='rectangle' className='zone-india-skelton'></Skeleton>}



                        {
                            !isLoading &&
                            <div className="box-shadow patients-dr-zone-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='west' colSpan={2}>West</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='west'>
                                                <b>Doctors</b>
                                                <span>{drZoneWise.length > 0 && drZoneWise[3].TotalDoctors}</span>
                                            </td>
                                            <td className='west'>
                                                <b>Patients</b>
                                                <span>{patientZoneWise.length > 0 && patientZoneWise[0].TotalPatients}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }


                        {isLoading && <Skeleton height="6rem" animation='wave' shape='rectangle' className='zone-india-skelton'></Skeleton>}


                        {
                            !isLoading &&
                            <div className="box-shadow patients-dr-zone-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='north' colSpan={2}>North</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='north'>
                                                <b>Doctors</b>
                                                <span>{drZoneWise.length > 0 && drZoneWise[1].TotalDoctors}</span>
                                            </td>
                                            <td className='north'>
                                                <b>Patients</b>
                                                <span>{patientZoneWise.length > 0 && patientZoneWise[2].TotalPatients}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }


                        {isLoading && <Skeleton height="6rem" animation='wave' shape='rectangle' className='zone-india-skelton'></Skeleton>}
                        {
                            !isLoading &&
                            <div className="box-shadow patients-dr-zone-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='south' colSpan={2}>South</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='south'>
                                                <b>Doctors</b>
                                                <span>{drZoneWise.length > 0 && drZoneWise[2].TotalDoctors}</span>
                                            </td>
                                            <td className='south'>
                                                <b>Patients</b>
                                                <span>{patientZoneWise.length > 0 && patientZoneWise[3].TotalPatients}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    );
};

export default React.memo(PanIndiaDrPatient);


